import { useCallback, useEffect, useState } from 'react';

import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { tournamentsClient } from 'src/apollo/client';
import { setVenues, useOrgId, useVenues } from 'src/apollo/local-state';
import { NATIONAL_SECTION_ID } from 'src/constants/orgId';
import { useOrgLevel, userIsGlobalAdmin } from 'src/utils/auth';
import { fetchRegionVenues } from 'src/utils/classic-api';
import { Options as Option } from 'src/utils/typedefs/membership';

export interface Venue {
  AuthorityID: string;
  Category: number;
  Classification: number;
  ExternalID: string;
  GroupID: string;
  GroupVenueName: string;
  HasDomain: boolean;
  ID: string;
  IsoTimeZone: string;
  Name: string;
  UrlSegment: string;
}

interface Data {
  sections: Venue[] | null;
  districts: Venue[] | null;
  sectionOptions: Option[] | null;
  districtOptions: Option[] | null;
  venues: Venue[];
}

/**
 * Takes optional `section` argument - which will return the applicable districts
 */
export const useSectionAndDistricts = (
  section?: Option | string,
  districtId?: string,
  disableApplicableFilter = false,
): Data => {
  const { t } = useTranslation();
  const userIsGlobal = userIsGlobalAdmin();
  const { isNational } = useOrgLevel();
  const orgId = useOrgId();
  const [sections, setSections] = useState<Venue[] | null>(null);
  const [districts, setDistricts] = useState<Venue[] | null>(null);
  const [sectionOptions, setSectionOptions] = useState<Option[] | null>(null);
  const [districtOptions, setDistrictOptions] = useState<Option[] | null>(null);
  const [allVenues, setAllVenues] = useState<Venue[] | null>(null);
  const venues = useVenues();

  const getVenues = useCallback(async () => {
    if (venues) return venues;
    return fetchRegionVenues();
  }, [venues]);

  const [error, setError] = useState<unknown>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const venues = await getVenues();
        if (venues) {
          setVenues(venues, tournamentsClient);
        }
        setAllVenues(venues);
        const sections = venues
          ?.filter((x) => x.GroupID === NATIONAL_SECTION_ID)
          ?.sort((a, z) => a.Name?.localeCompare(z.Name));
        const districts = venues
          ?.filter((x) => x.GroupID !== NATIONAL_SECTION_ID)
          ?.sort((a, z) => a.Name?.localeCompare(z.Name));
        if (sections && !districtId) {
          const sectionOptions = getDistrictOrSectionOptions(sections);
          if (userIsGlobal || isNational || disableApplicableFilter) {
            setSectionOptions([getDefaultSectionsOption(t), ...sectionOptions]);
          } else {
            const applicableDistrict = districts?.find((district) => district.ID === orgId);
            const applicableSection = sections?.find(
              (section) => section.ID === applicableDistrict?.GroupID || section.ID === orgId,
            );
            const applicableSectionOptions = applicableSection ? getDistrictOrSectionOptions([applicableSection]) : [];
            setSectionOptions(applicableSectionOptions);
          }
        }

        if (districts && section && !districtId) {
          const applicableDistricts = districts.filter(
            (district) => district?.GroupID === (typeof section === 'object' ? section?.value : section),
          );
          const districtOptions = getDistrictOrSectionOptions(applicableDistricts);
          setDistrictOptions([getDefaultDistrictsOption(t), ...districtOptions]);
        }

        if (districtId) {
          const district = districts?.find((d) => d?.ID === districtId);
          const section = sections?.find((s) => s?.ID === district?.GroupID);
          const applicableDistricts = districts?.filter((d) => d?.GroupID === section?.ID);
          setSectionOptions(getDistrictOrSectionOptions([section]));
          setDistrictOptions([getDefaultDistrictsOption(t), ...getDistrictOrSectionOptions(applicableDistricts)]);
        }

        setSections(sections);
        setDistricts(districts);
        setLoading(false);
      } catch (error) {
        setError(error);
      }
    };
    fetchData();
  }, [section, userIsGlobal, isNational, orgId, districtId, disableApplicableFilter, getVenues, t]);

  return {
    sectionOptions,
    districtOptions,
    sections,
    districts,
    loading,
    error,
    venues: allVenues,
  } as any;
};

export const getDistrictOrSectionOptions = (districtsOrSections: Venue[]): Option[] => {
  return districtsOrSections?.map((o) => ({
    value: o.ID,
    label: o.Name,
  }));
};
export const getDefaultSectionsOption = (t: TFunction) => ({ label: t('section all'), value: '' });
export const getDefaultDistrictsOption = (t: TFunction) => ({ label: t('district all'), value: '' });
