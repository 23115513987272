/* eslint-disable complexity */
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import dayjs from 'dayjs';
import { print } from 'graphql';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { meshGatewayClient, socialLeaguesClient } from 'src/apollo/client';
import { useOrgId } from 'src/apollo/local-state';
import AdminTable, { DataCols } from 'src/components/admin-table/admin-table';
import Button from 'src/components/button/button';
import EmptyState from 'src/components/empty-state/empty-state';
import FloatingNotification from 'src/components/floating-notification/floating-notification';
import { OnReportUploadStateChange, ReportUploadState } from 'src/components/generate-report/generate-report';
import ModeReport from 'src/components/mode-report/mode-report';
import Panel from 'src/components/panel/panel';
import ReportFilters, { ReportFilter } from 'src/components/report-filters/report-filters';
import { useControlledQuery } from 'src/components/table-controls/table-controls';
import { PageMaxWidth } from 'src/components/util-components/util-components';
import { getEnvConfig } from 'src/config/config';
import { userIsGlobalAdmin } from 'src/utils/auth';
import { formatDate } from 'src/utils/helper/membership';

import DownloadReportButton from './download-button/download-report-button';
import {
  GET_LEAGUES_REPORT,
  GET_PARTICIPANTS_REPORT,
  GET_PLAYTRACKER_POINTS_REPORT,
  GET_PLAYTRACKER_REPORT,
  GET_PROVIDERS_REPORT,
} from './reports-queries';
import * as styles from './reports.module.less';
import { isModeReport, isPlayTrackerReport, ReportType } from './utils';

const Reports: React.FC = () => {
  const { t } = useTranslation();
  const userIsGlobal = userIsGlobalAdmin();

  const INIT_FILTER: ReportFilter = useMemo(() => {
    return userIsGlobal
      ? {
          dates: 'days7',
          reportType: ReportType.LEAGUES,
          reportLabel: t('leagues report option', { type: ReportType.LEAGUES }),
        }
      : {
          dates: 'days7',
          reportType: ReportType.PLAYERS,
          reportLabel: t('playtracker report option', { type: ReportType.PLAYERS }),
        };
  }, [userIsGlobal, t]);

  const [{ reportType, dates, to, from, reportLabel, sectionId, districtId }, setTableFilter] =
    useState<ReportFilter>(INIT_FILTER);
  const [reportUploadState, setReportUploadState] = useState<ReportUploadState>('none');
  const [popupClosed, setPopupClosed] = useState(false);
  const modeDownloadUrl = useRef('');
  const orgId = useOrgId();

  const [generate, setGenerate] = useState<boolean>(false);
  const [downloadable, setDownloadable] = useState<boolean>(false);

  const activeReportQuery = useMemo(() => {
    switch (reportType) {
      case ReportType.LEAGUES:
        return print(GET_LEAGUES_REPORT);
      case ReportType.PARTICIPANTS:
        return print(GET_PARTICIPANTS_REPORT);
      case ReportType.PROVIDERS:
        return print(GET_PROVIDERS_REPORT);
      case ReportType.PLAYERS:
        return print(GET_PLAYTRACKER_REPORT);
      case ReportType.POINTS:
        return print(GET_PLAYTRACKER_POINTS_REPORT);
    }
  }, [reportType]);

  const formattedDate = transformDateFilter(from, to, dates);

  const getModeFiltersForReport = (reportType: string): Record<string, any> | undefined => {
    const format = (date) => new Date(date).toISOString();
    switch (reportType) {
      case ReportType.RESULTS:
        return {
          ParentOrganisationID: orgId,
          Start: formattedDate ? format(formattedDate.between.start) : '',
          End: formattedDate ? format(formattedDate.between.end) : '',
        };
      case ReportType.ROSTER:
        return {
          ParentOrganisationID: orgId,
        };
      default:
        return {};
    }
  };

  const getModeIdForReport = (reportType: string): string | undefined => {
    // planning for this to eventually live in strapi
    switch (reportType) {
      case ReportType.RESULTS:
        return getEnvConfig().RESULTS_REPORT_ID;
      case ReportType.ACTIVITY:
        return getEnvConfig().ACTIVITY_REPORT_ID;
      case ReportType.ROSTER:
        return getEnvConfig().ROSTER_REPORT_ID;
      default:
        return undefined;
    }
  };

  const onFilterChange = useCallback(
    (filters: ReportFilter) => {
      setGenerate(false);
      setTableFilter(filters);
      setReportUploadState('none');
      setPopupClosed(true);
      setDownloadable(false);
    },
    [setTableFilter, setReportUploadState, setPopupClosed],
  );

  const onReportUploadStateChange = useCallback<OnReportUploadStateChange>(
    (state) => {
      if (state === 'processing') {
        setPopupClosed(false);
      }
      setReportUploadState(state);
    },
    [setReportUploadState, setPopupClosed],
  );

  const isButtonDisabled = () => {
    return dates === 'custom' && (!to || !from);
  };

  const [hasData, setHasData] = useState(false);
  return (
    <PageMaxWidth>
      <Panel floatingTitle="Reports" spacing={{ margins: { md: 'bottom' } }}>
        <ReportFilters
          loading={reportUploadState === 'processing'}
          initFilter={INIT_FILTER}
          onFilterChange={onFilterChange}
          actionButton={
            <Button
              size="md"
              spacing={{ margins: { md: 'horizontal', xs: 'bottom' } }}
              disabled={isButtonDisabled()}
              hide={isModeReport(reportType)}
              onClick={() => setGenerate(true)}
              level="secondary"
            >
              {t('generate')}
            </Button>
          }
        />
      </Panel>
      <Panel
        title={
          <span className={styles.subTitlePadding}>
            {reportLabel}{' '}
            {formattedDate?.between && isPlayTrackerReport(reportType) && (
              <span className={styles.subTitle}>
                | {formatDate(formattedDate?.between.start, 'll')} - {formatDate(formattedDate?.between.end, 'll')}
              </span>
            )}
          </span>
        }
        headerEndContent={
          <DownloadReportButton
            isModeReport={isModeReport(reportType)}
            modeDownloadUrl={modeDownloadUrl}
            reportQuery={activeReportQuery}
            reportUploadState={reportUploadState}
            onReportUploadStateChange={onReportUploadStateChange}
            hasData={hasData}
            downloadable={downloadable}
            setDownloadable={setDownloadable}
            reportType={reportType}
            filters={{
              timestamp: formattedDate,
              ...(sectionId ? { sectionId } : {}),
              ...(districtId ? { districtId } : {}),
            }}
          />
        }
      >
        {reportType === ReportType.LEAGUES && <LeaguesReport setHasData={setHasData} />}
        {reportType === ReportType.PARTICIPANTS && <ParticpantsReport setHasData={setHasData} />}
        {reportType === ReportType.PROVIDERS && <ProvidersReport setHasData={setHasData} />}
        {reportType === ReportType.PLAYERS && (
          <PlayersReport
            start={from}
            end={to}
            type={dates}
            sectionId={sectionId}
            districtId={districtId}
            generate={generate}
            setHasData={setHasData}
          />
        )}
        {reportType === ReportType.POINTS && (
          <PointsReport
            start={from}
            end={to}
            type={dates}
            sectionId={sectionId}
            districtId={districtId}
            generate={generate}
            setHasData={setHasData}
          />
        )}
        {isModeReport(reportType) && (
          <ModeReport
            reportName={getModeIdForReport(reportType)}
            filters={getModeFiltersForReport(reportType)}
            showDownloadButton={false}
            setModeDownloadUrl={(url) => (modeDownloadUrl.current = url)}
          />
        )}
      </Panel>
      {reportUploadState === 'processing' && !popupClosed && (
        <FloatingNotification
          message={t('preparing download')}
          onClose={() => setPopupClosed(true)}
          variant="download"
          hideCloseButton
        />
      )}
      {reportUploadState === 'downloadable' && !popupClosed && (
        <FloatingNotification
          icon={{ name: 'md-tick-circle', className: styles.tick }}
          message={t('report downloaded')}
          variant="downloaded"
          onClose={() => setPopupClosed(true)}
        />
      )}
    </PageMaxWidth>
  );
};

interface PlayTrackerProps {
  start: Date | undefined;
  end: Date | undefined;
  type: string;
  generate: boolean;
  sectionId?: string;
  districtId?: string;
  setHasData: (val: boolean) => void;
}

interface Filter {
  between: {
    start: Date;
    end: Date;
  };
}

const transformDateFilter = (start, end, type): Filter => {
  let f;
  if (type === 'custom' && start && end) {
    f = {
      between: {
        start: start?.toISOString(),
        end: end?.toISOString(),
      },
    };
  } else if (type !== 'custom') {
    f = convertDate(type);
  }
  return f;
};

const convertDate = (date) => {
  let startDate;
  let endDate = new Date();
  if (date === 'days7') {
    startDate = dayjs().subtract(7, 'days');
  }
  if (date === 'days30') {
    startDate = dayjs().subtract(30, 'days');
  }
  if (date === 'month') {
    startDate = dayjs().startOf('month');
    endDate = startDate.clone().endOf('month');
  }
  if (date === 'prevMonth') {
    const prevMonth = dayjs().subtract(1, 'month');
    startDate = prevMonth.clone().startOf('month');
    endDate = startDate.clone().endOf('month');
  }
  if (date === 'year') {
    startDate = dayjs().startOf('year');
  }

  if (startDate && endDate) {
    return {
      between: {
        start: startDate.toISOString(),
        end: endDate.toISOString(),
      },
    };
  } else {
    return {};
  }
};

const PlayersReport: React.FC<PlayTrackerProps> = ({
  start,
  end,
  type,
  sectionId,
  districtId,
  generate,
  setHasData,
}) => {
  const { t } = useTranslation();
  const [filter, setFilter] = useState<Filter | any | undefined>(undefined);
  useEffect(() => {
    const timestamp = transformDateFilter(start, end, type);
    setFilter({
      timestamp,
      ...(sectionId ? { sectionId } : {}),
      ...(districtId ? { districtId } : {}),
    });
  }, [start, end, type, sectionId, districtId]);

  const { data, loading, controlProps } = useControlledQuery(GET_PLAYTRACKER_REPORT, {
    client: meshGatewayClient,
    skip: !generate,
    fetchPolicy: 'no-cache',
    variables: {
      filter,
    },
    getTotalItems: (d) => d?.activityPlayerReport?.totalItems,
    transformVariables: (v) => {
      const { offset, ...vars } = v;
      return { ...vars, skip: offset };
    },
  });

  useEffect(() => {
    const hasData = Boolean(controlProps?.totalItems);
    setHasData(hasData);
  }, [controlProps?.totalItems, data, setHasData]);

  const filters = useMemo(() => ({ filter, sectionId, districtId }), [filter, sectionId, districtId]);

  return (
    <AdminTable
      filters={filters}
      data={data?.activityPlayerReport?.items}
      columns={
        [
          {
            key: 'name',
            title: t('name'),
            getValue: (r) => r.name || t('n/a'),
          },
          { key: 'section', title: t('section'), getValue: (r) => r.section || t('n/a') },
          {
            key: 'district',
            title: t('district'),
            getValue: (r) => r.district || t('n/a'),
          },
          { key: 'age', title: t('age'), getValue: (r) => r.age || t('n/a') },
          {
            key: 'competitionLevel',
            title: t('competition level'),
            getValue: (r) => r.ballColour || t('n/a'),
          },
          {
            key: 'coachLevel',
            title: t('coach level'),
            getValue: (r) => r.coachAssignedLevel || t('n/a'),
          },
          {
            key: 'redPoints',
            title: t('red points'),
            getValue: (r) => r.redPoints,
          },
          {
            key: 'totalRedPoints',
            title: t('total red points'),
            getValue: (r) => r.totalRedPoints,
          },
          {
            key: 'orangePoints',
            title: t('orange points'),
            getValue: (r) => r.orangePoints,
          },
          {
            key: 'totalOrangePoints',
            title: t('total orange points'),
            getValue: (r) => r.totalOrangePoints,
          },
          {
            key: 'redOrangePoints',
            title: t('red + orange points'),
            getValue: (r) => r.redOrangePoints,
          },
          {
            key: 'totalRedOrangePoints',
            title: t('total red + orange points'),
            getValue: (r) => r.redOrangePoints,
          },
          {
            key: 'greenPlayPoints',
            title: t('green play points'),
            getValue: (r) => r.greenPlayPoints,
          },
          {
            key: 'greenWinPoints',
            title: t('green win points'),
            getValue: (r) => r.greenWinPoints,
          },
          {
            key: 'totalGreenPoints',
            title: t('total green points'),
            getValue: (r) => r.totalGreenPoints,
          },
        ] as DataCols<any>
      }
      loading={loading}
      controls={{ ...controlProps, sortDisabled: true }}
      noDataComponent={<NoDataAvailable parameters={true} />}
      //spacing={{ margins: { sm: 'top' } }}
    />
  );
};

const PointsReport: React.FC<PlayTrackerProps> = ({
  start,
  end,
  type,
  sectionId,
  districtId,
  generate,
  setHasData,
}) => {
  const { t } = useTranslation();
  const [filter, setFilter] = useState<Filter | any | undefined>(undefined);

  useEffect(() => {
    const timestamp = transformDateFilter(start, end, type);
    setFilter({
      timestamp,
      ...(sectionId ? { sectionId } : {}),
      ...(districtId ? { districtId } : {}),
    });
  }, [start, end, type, sectionId, districtId]);

  const { data, loading, controlProps } = useControlledQuery(GET_PLAYTRACKER_POINTS_REPORT, {
    client: meshGatewayClient,
    skip: !generate,
    fetchPolicy: 'no-cache',
    variables: {
      filter,
    },
    getTotalItems: (d) => d?.activityPointReport?.totalItems,
    transformVariables: (v) => {
      const { offset, ...vars } = v;
      return { ...vars, skip: offset };
    },
  });

  useEffect(() => {
    const hasData = Boolean(controlProps?.totalItems);
    setHasData(hasData);
  }, [controlProps?.totalItems, data, setHasData]);

  const filters = useMemo(() => ({ filter, sectionId, districtId }), [filter, sectionId, districtId]);

  return (
    <AdminTable
      data={data?.activityPointReport?.items}
      filters={filters}
      columns={
        [
          {
            key: 'name',
            title: t('event date'),
            getValue: (r) => r.eventDate || t('n/a'),
          },
          { key: 'event', title: t('event'), getValue: (r) => r.eventType || t('n/a') },
          {
            key: 'section',
            title: t('section'),
            getValue: (r) => r.section || t('n/a'),
          },
          {
            key: 'district',
            title: t('district'),
            getValue: (r) => r.district || t('n/a'),
          },
          {
            key: 'location',
            title: t('location'),
            getValue: (r) => r.location || t('n/a'),
          },
          {
            key: 'player',
            title: t('player'),
            getValue: (r) => r.playerName || t('n/a'),
          },
          {
            key: 'point type',
            title: t('point type'),
            getValue: (r) => r?.ballColour || t('n/a'),
          },
          { key: 'points', title: t('points'), getValue: (r) => r?.totalPoints || t('n/a') },
        ] as DataCols<any>
      }
      loading={loading}
      controls={{ ...controlProps, sortDisabled: true }}
      spacing={{ margins: { sm: 'top' } }}
      noDataComponent={<NoDataAvailable parameters={true} />}
    />
  );
};

interface ReportProps {
  setHasData: (val: boolean) => void;
}

const LeaguesReport: React.FC<ReportProps> = ({ setHasData }) => {
  const { t } = useTranslation();
  const { data, loading, controlProps } = useControlledQuery(GET_LEAGUES_REPORT, {
    ...controlledReportQueryOptions,
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    const hasData = Boolean(controlProps?.totalItems);
    setHasData(hasData);
  }, [controlProps?.totalItems, data, setHasData]);

  return (
    <AdminTable
      data={data?.report?.items}
      columns={
        [
          { key: 'facilityName', title: t('facility'), getValue: (r) => r.facilityName },
          { key: 'facilityAddress', title: t('address'), getValue: (r) => r.facilityAddress },
          { key: 'leagueName', title: t('league'), getValue: (r) => r.leagueName },
          {
            key: 'leagueStatus',
            title: t('status'),
            getValue: (r) => leagueStatus(r.leagueStatus, t),
          },
          { key: 'providerName', title: t('provider'), getValue: (r) => r.providerName },
          { key: 'registrations', title: t('registrations'), getValue: (r) => r.registrations },
          { key: 'revenue', title: t('revenue'), getValue: (r) => r.revenue },
          { key: 'teams', title: t('teams'), getValue: (r) => r.teams },
        ] as DataCols<any>
      }
      loading={loading}
      controls={{ ...controlProps, sortDisabled: true }}
      spacing={{ margins: { md: 'top' } }}
      noDataComponent={<NoDataAvailable parameters={false} />}
    />
  );
};

const ParticpantsReport: React.FC<ReportProps> = ({ setHasData }) => {
  const { data, loading, controlProps } = useControlledQuery(GET_PARTICIPANTS_REPORT, {
    ...controlledReportQueryOptions,
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    const hasData = Boolean(controlProps?.totalItems);
    setHasData(hasData);
  }, [controlProps?.totalItems, data, setHasData]);

  return (
    <AdminTable
      data={data?.report?.items}
      columns={
        [
          { key: 'participantName', title: 'Name', getValue: (r) => r.participantName },
          { key: 'gender', title: 'Gender', getValue: (r) => r.gender },
          { key: 'participantAge', title: 'Age', getValue: (r) => r.participantAge },
          { key: 'postcode', title: 'Postcode', getValue: (r) => r.postcode },
          {
            key: 'leagueRegistrations',
            title: 'League registrations',
            getValue: (r) => r.leagueRegistrations,
          },
          { key: 'totalSpent', title: 'Total spent', getValue: (r) => r.totalSpent },
        ] as DataCols<any>
      }
      loading={loading}
      controls={{ ...controlProps, sortDisabled: true }}
      spacing={{ margins: { md: 'top' } }}
      noDataComponent={<NoDataAvailable parameters={false} />}
    />
  );
};

const ProvidersReport: React.FC<ReportProps> = ({ setHasData }) => {
  const { data, loading, controlProps } = useControlledQuery(GET_PROVIDERS_REPORT, controlledReportQueryOptions);

  useEffect(() => {
    const hasData = Boolean(controlProps?.totalItems);
    setHasData(hasData);
  }, [controlProps?.totalItems, data, setHasData]);

  return (
    <AdminTable
      data={data?.report?.items}
      columns={
        [
          { key: 'leagueProviderName', title: 'Provider', getValue: (r) => r.leagueProviderName },
          { key: 'activeLeagues', title: 'Active leagues', getValue: (r) => r.activeLeagues },
          {
            key: 'cancelledLeagues',
            title: 'Cancelled leagues',
            getValue: (r) => r.cancelledLeagues,
          },
          {
            key: 'completedLeagues',
            title: 'Completed leagues',
            getValue: (r) => r.completedLeagues,
          },
          {
            key: 'totalParticipants',
            title: 'Total participants',
            getValue: (r) => r.totalParticipants,
          },
          { key: 'totalRevenue', title: 'Total revenue', getValue: (r) => r.totalRevenue },
        ] as DataCols<any>
      }
      loading={loading}
      controls={{ ...controlProps, sortDisabled: true }}
      spacing={{ margins: { md: 'top' } }}
      noDataComponent={<NoDataAvailable parameters={false} />}
    />
  );
};

const controlledReportQueryOptions = {
  client: socialLeaguesClient,
  getTotalItems: (d) => d?.report?.totalItems,
  transformVariables: (v) => {
    const { offset, ...vars } = v;
    return { ...vars, skip: offset };
  },
};

interface NoDataProps {
  parameters: boolean;
}

const NoDataAvailable: React.FC<NoDataProps> = ({ parameters }) => {
  const { t } = useTranslation();
  return (
    <EmptyState
      title={t('no data available')}
      subtitle={parameters ? t('adjust parameters') : null}
      icon="xl-document"
    />
  );
};

type LeagueStatus = 'UPCOMING' | 'IN_PROGRESS' | 'COMPLETED' | 'CANCELLED';
const leagueStatus = (status: LeagueStatus, t: TFunction) => {
  switch (status) {
    case 'COMPLETED':
      return t('completed');
    case 'CANCELLED':
      return t('cancelled');
    case 'IN_PROGRESS':
      return t('in progress');
    case 'UPCOMING':
      return t('upcoming');
    default:
      return t('unknown');
  }
};

export default Reports;
