import React, { useEffect, useMemo, useState } from 'react';

import { JointDatePicker } from 'src/components/date-picker/date-picker';
import Dropdown, { Option } from 'src/components/dropdown/dropdown';

import 'react-datepicker/dist/react-datepicker-cssmodules.css';

import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { useOrgId } from 'src/apollo/local-state';
import FilterSet, { Filter } from 'src/components/filter-set/filter-set';
import { ReportType } from 'src/components/reports/utils';
import { NATIONAL_SECTION_ID } from 'src/constants/orgId';
import useFilters from 'src/hooks/use-filters';
import { getDefaultSectionsOption, useSectionAndDistricts } from 'src/hooks/use-section-and-districts';
import { userIsGlobalAdmin } from 'src/utils/auth';

export type DatePreset = 'days7' | 'days30' | 'month' | 'prevMonth' | 'year' | 'custom';

export interface ReportFilter {
  to?: Date;
  from?: Date;
  dates: DatePreset;
  reportType: ReportType;
  reportLabel?: string;
  sectionId?: string;
  districtId?: string;
}

interface Props {
  onFilterChange?: (filter: ReportFilter) => any;
  initFilter: ReportFilter;
  loading?: boolean;
  actionButton?: any;
}

interface ReportsOpts extends Option {
  value: ReportFilter['reportType'];
}

interface DatesOpts extends Option {
  value: ReportFilter['dates'];
}

export enum FilterType {
  SECTION = 'section',
  DISTRICT = 'district',
  DATE = 'date',
}

export const getAllSectionsOption = (t: TFunction) => ({ label: t('section all'), value: '' });
export const getAllDistrictsOption = (t: TFunction) => ({ label: t('district all'), value: '' });

interface OtherFilterPreset {
  type: FilterType.SECTION | FilterType.DISTRICT;
}

interface DateFilterPreset {
  type: FilterType.DATE;
  initialValue?: DatePreset;
}

type FilterPreset = DateFilterPreset | OtherFilterPreset;

export const ReportFilterMap: Record<ReportType, FilterPreset[]> = {
  [ReportType.PLAYERS]: [{ type: FilterType.DATE }, { type: FilterType.SECTION }, { type: FilterType.DISTRICT }],
  [ReportType.POINTS]: [{ type: FilterType.DATE }, { type: FilterType.SECTION }, { type: FilterType.DISTRICT }],
  [ReportType.LEAGUES]: [],
  [ReportType.PARTICIPANTS]: [],
  [ReportType.PROVIDERS]: [],
  [ReportType.RESULTS]: [{ type: FilterType.DATE, initialValue: 'days30' }],
  [ReportType.ACTIVITY]: [],
  [ReportType.ROSTER]: [],
};

const ReportFilterSet: React.FC<Props> = ({ onFilterChange, initFilter, loading, actionButton }) => {
  const [filters, setFilters] = useFilters({ initialFilter: initFilter, onChange: onFilterChange });
  const { from, to, reportType, dates } = filters;
  const { t } = useTranslation();
  const userIsGlobal = userIsGlobalAdmin();
  const orgId = useOrgId();

  useEffect(() => {
    ReportFilterMap[reportType].forEach((filterPreset) => {
      if (filterPreset.type === FilterType.DATE && filterPreset.initialValue !== undefined) {
        setFilters({ dates: filterPreset.initialValue });
      }
    });
    // need to disable eslint rule because setFilters changes when it gets called and leads to infinite loop
  }, [reportType]); // eslint-disable-line react-hooks/exhaustive-deps

  const [section, setSection] = useState<any>('');
  const [district, setDistrict] = useState<any>('');
  const { sectionOptions, districtOptions } = useSectionAndDistricts(section);

  const filteredSectionOptions = useMemo(() => {
    const removeValueIds = [
      'be33b626-8764-4472-b34f-a77c650e1771', // UNASSIGNED
      NATIONAL_SECTION_ID,
    ];
    return sectionOptions?.filter((s) => !removeValueIds.includes(s.value));
  }, [sectionOptions]);

  const sortedSectionOptions = useMemo(() => {
    const options = filteredSectionOptions?.filter((option) => option.value !== ''); // Remove default option
    const sortedSectionOptions = [...(options || [])]?.sort((a, z) => a.label?.localeCompare(z.label));
    return [getDefaultSectionsOption(t), ...sortedSectionOptions];
  }, [filteredSectionOptions, t]);

  const reportOpts: ReportsOpts[] = useMemo(() => {
    const opts: ReportsOpts[] = [
      {
        label: t('playtracker report option', { type: ReportType.PLAYERS }),
        value: ReportType.PLAYERS,
      },
      {
        label: t('playtracker report option', { type: ReportType.POINTS }),
        value: ReportType.POINTS,
      },
    ];
    if (userIsGlobal) {
      const leagueTitle = 'leagues report option';
      opts.unshift(
        {
          label: t(leagueTitle, { type: ReportType.LEAGUES }),
          value: ReportType.LEAGUES,
        },
        {
          label: t(leagueTitle, { type: ReportType.PARTICIPANTS }),
          value: ReportType.PARTICIPANTS,
        },
        { label: t(leagueTitle, { type: ReportType.PROVIDERS }), value: ReportType.PROVIDERS },
      );
    }
    opts.push({ label: t('match results report'), value: ReportType.RESULTS });
    if (orgId === NATIONAL_SECTION_ID) {
      opts.push({ label: t('activity report'), value: ReportType.ACTIVITY });
    }
    opts.push({ label: t('player roster report'), value: ReportType.ROSTER });
    return opts;
  }, [t, userIsGlobal, orgId]);

  const dateOpts: DatesOpts[] = useMemo(
    () => [
      { label: t('last 7 days'), value: 'days7' },
      { label: t('last 30 days'), value: 'days30' },
      { label: t('this month'), value: 'month' },
      { label: t('last month'), value: 'prevMonth' },
      { label: t('this year'), value: 'year' },
      { label: t('custom'), value: 'custom' },
    ],
    [t],
  );

  function handleDateChange(date) {
    // initial change start by setting the startDate
    if (!filters.from && !filters.to) {
      setFilters({ from: date });

      // startDate has been set, set the end date
    } else if (filters.from && !filters.to) {
      setFilters({ to: date });
    }

    //Prevent end date being before start date
    if (from && date < from) {
      setFilters({ from: date, to: undefined });
    }

    // user is choosing another range => set the start date
    // and set the endDate back to null
    if (filters.from && filters.to) {
      setFilters({ from: date, to: undefined });
    }
  }

  const enabledFilters = ReportFilterMap[filters.reportType].map((filterPreset) => filterPreset.type);

  return (
    <FilterSet spacing={{ margins: { sm: 'bottom' } }}>
      <Filter>
        <Dropdown
          disabled={loading}
          selected={reportType}
          options={reportOpts}
          onSelect={(o) => setFilters({ reportType: o.value as ReportFilter['reportType'], reportLabel: o.label })}
        />
      </Filter>
      {enabledFilters.includes(FilterType.SECTION) && (
        <Filter>
          <Dropdown
            selected={section?.value ?? ''}
            options={sortedSectionOptions ?? []}
            placeholder={t('section all')}
            onSelect={(o) => {
              setSection(o);
              setDistrict(''); // Reset district on section change
              setFilters({ ...filters, sectionId: o.value, districtId: '' }); // Reset district on section change
            }}
            spacing={{ margins: { xs: 'right' } }}
          />
        </Filter>
      )}
      {enabledFilters.includes(FilterType.DISTRICT) && (
        <Filter>
          <Dropdown
            selected={district?.value ?? ''}
            options={districtOptions ?? []}
            placeholder={t('district all')}
            onSelect={(o) => {
              setDistrict(o);
              setFilters({ districtId: o.value });
            }}
            spacing={{ margins: { xs: 'right' } }}
            disabled={!section?.value}
          />
        </Filter>
      )}
      {enabledFilters.includes(FilterType.DATE) && (
        <Filter>
          <Dropdown
            disabled={loading}
            selected={dates}
            options={dateOpts}
            onSelect={(o) => setFilters({ dates: o.value as ReportFilter['dates'] })}
            spacing={{ margins: { xs: 'right' } }}
          />
          {dates === 'custom' && (
            <JointDatePicker
              selectedStart={from}
              selectedEnd={to}
              onChange={(d) => handleDateChange(d)}
              maxDate={to}
              disabled={loading}
            />
          )}
        </Filter>
      )}
      {actionButton}
    </FilterSet>
  );
};

export default ReportFilterSet;
