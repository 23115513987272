import graphql from 'graphql-tag';

export const GET_LEAGUES_REPORT = graphql`
  query LeagueRegistrationsAndTeamsReport($limit: Int!, $skip: Int!) {
    report: leagueRegistrationsAndTeamsReport(queryParameters: { offset: $skip, limit: $limit }) {
      totalItems
      items {
        facilityAddress
        facilityName
        leagueName
        leagueStatus
        providerName
        registrations
        revenue
        teams
      }
    }
  }
`;

export const GET_PROVIDERS_REPORT = graphql`
  query LeagueProvidersReport($limit: Int!, $skip: Int!) {
    report: leagueProvidersReport(queryParameters: { offset: $skip, limit: $limit }) {
      totalItems
      items {
        activeLeagues
        cancelledLeagues
        completedLeagues
        leagueProviderName
        totalParticipants
        totalRevenue
      }
    }
  }
`;

export const GET_PARTICIPANTS_REPORT = graphql`
  query LeagueParticipantsReport($limit: Int!, $skip: Int!) {
    report: leagueParticipantsReport(queryParameters: { offset: $skip, limit: $limit }) {
      totalItems
      items {
        gender
        leagueRegistrations
        participantAge
        participantName
        postcode
        totalSpent
      }
    }
  }
`;

export const GET_PLAYTRACKER_REPORT = graphql`
  query activityPlayerReport($limit: Int!, $skip: Int!, $filter: ActivityPlayerReportFilterOptions) {
    activityPlayerReport(filter: $filter, pageArgs: { limit: $limit, skip: $skip }) {
      totalItems
      items {
        name
        section
        district
        age
        ballColour
        coachAssignedLevel
        redPoints
        totalRedPoints
        orangePoints
        totalOrangePoints
        redOrangePoints
        totalRedOrangePoints
        greenPlayPoints
        greenWinPoints
        totalGreenPoints
        daysToAdvance
      }
    }
  }
`;

export const GET_PLAYTRACKER_POINTS_REPORT = graphql`
  query activityPointsReport($limit: Int!, $skip: Int!, $filter: ActivityPointReportFilterOptions) {
    activityPointReport(filter: $filter, pageArgs: { limit: $limit, skip: $skip }) {
      totalItems
      items {
        eventDate
        eventType
        location
        section
        district
        playerName
        ballColour
        playPoints
        winPoints
        totalPoints
      }
    }
  }
`;

export const GENERATE_CSV_REPORT = graphql`
  mutation GenerateCSVReport($query: String!, $endpoint: String!, $authHeader: String) {
    addReport(
      input: {
        query: $query
        graphqlEndpoint: $endpoint
        outputFormat: CSV
        paginator: { rootFieldPath: "report.items" }
        authHeader: $authHeader
      }
    ) {
      id
      url
    }
  }
`;

export const CHECK_UPLOAD_STATE = graphql`
  query CheckUploadState($reportId: ID!) {
    reportMetadata(id: $reportId) {
      uploaded
      status {
        state
        message
      }
      url
      id
    }
  }
`;
